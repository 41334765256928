import { gql } from "@apollo/client";

export const loginMutation = gql`
  mutation login($userName: String!, $password: String!) {
    classMethods {
      User {
        login(userName: $userName, password: $password) {
          success
          requireRadius
          authLocation
          targetLocation
        }
      }
    }
  }
`;


export const getCurrentUserQuery = gql`
  query getCurrentUser {
    classMethods {
      User {
        getCurrentUser {
          id
          userName
          disabled
          email
          firstName
          lastName
          deviceLimit
          mobileNumber
          mobileCountry
          unitNumber
          rateProfileId
          rateProfile {
            id
            name
            description
            profile
          }
          overLimit
          requireAcceptTNC
          accounts {
            edges {
              node {
                id
                name
              }
            }
          }
          addresses {
            total
            edges {
              node {
                id
                streetNo
                address
                suburb
                state
                postCode
                unitNo
                buildingId
                countryId
                country {
                  id
                  name
                }
              }
            }
          }
          role {
            id
            name
          }
          activePlan: getActivePlan {
            id
            usage
            unlimited
            total
            start
            end
            product {
              id
              name
              type
              description
              hidden
              once
              value
              rateProfile {
                id
                name
                description
                profile
              }
            }
          }
          activeOrders: getActiveOrders {
            id
            renewalPeriod
            renewsAt
            status
            accountId
            orderItems {
              edges {
                node {
                  id
                  renewable
                  productId
                  product {
                    id
                    name
                    type
                    description
                    hidden
                    once
                    value
                    rateProfile {
                      id
                      name
                      description
                    }
                  }
                }
              }
            }
          }
          devices {
            edges {
              node {
                id
                macAddress
                description
                disabled
                networkProfile
                clientId
                ip4
                ip6
                leaseExpiry
                hostname
                manufacturer
                isActive
              }
            }
          }
          buildingId
          building {
            id
            name
            code
            formattedAddress
            ewayCountry
          }
          groups {
            edges {
              node {
                id
                name
                active
                code
              }
            }
          }
          activeGroup: getActiveGroup {
            id
            name
            membershipEnd
            membershipStart
          }
          hasUserTOTP
        }
      }
    }
  }
`;

export const updateUserMutation = gql`
  mutation updateUser($userId: ID, $input: UserOptionalInput!) {
    models {
      User(update: {
        where: {
          id: {eq: $userId}
        }
        limit: 1
        input: $input
      }) {
        id
      }
    }
  }
`;

export const setPasswordMutation = gql`
  mutation setPassword ($id: ID!, $password: String!, $oldPassword: String!) {
    classMethods {
      UserAuth {
        setPassword (id: $id, password: $password, oldPassword: $oldPassword)
      }
    }
  }
`;

export const purchaseMutation = gql`
  mutation changeOrderMutation(
    $ip: String
    $userId: String!
    $cardNumber: String
    $cardCCV: String
    $cardName: String
    $cardExpiry: String
    $voucherCode: String
    $products: [String]
    $schedule: Boolean,
    ) {
    classMethods {
      User {
        purchase(
          ip: $ip
          userId: $userId
          cardNumber: $cardNumber
          cardCCV: $cardCCV
          cardName: $cardName
          cardExpiry: $cardExpiry
          voucherCode: $voucherCode
          products: $products
          schedule: $schedule,
          ) {
          id
          userName
        }
      }
    }
  }
`;

export function getPurchaseResult({ data }) {
  return data?.classMethods?.User?.purchase;
}

// export const purchaseOrderMutation = gql`
//   mutation changeOrderMutation(
//     $ip: String
//     $userId: ID!
//     $ccard: createEwayCustomerCCard
//     $voucherCode: String
//     $ewayCountry: String
//     $products: [String]
//   ) {
//     classMethods {
//       User {
//         changeUserOrder(
//           ip: $ip
//           userId: $userId
//           ccard: $ccard
//           voucherCode: $voucherCode
//           ewayCountry: $ewayCountry
//           products: $products
//         ) {
//           id
//         }
//       }
//     }
//   }
// `;

// export function getPurchaseOrderResult({ data }) {
//   return data?.classMethods?.User?.changeUserOrder;
// }

export const logoutMutation = gql`
  mutation {
    classMethods {
      User {
        logout
      }
    }
  }
`;

export const cancelUserRenewalMutation = gql`
  mutation cancelUserRenewal ($userId: ID!, $userRenewalId: ID!, $email: String!) {
    classMethods {
      UserRenewal {
        cancelUserRenewal (userId: $userId, userRenewalId: $userRenewalId, email: $email)
      }
    }
  }
`;

export function getCancelUserRenewalResult({ data }) {
  return data?.classMethods?.UserRenewal?.cancelUserRenewal;
}


export const cancelUseOrderMutation = gql`
  mutation cancelOrder ($orderId: ID!) {
    classMethods {
      Order {
        cancelOrder (orderId: $orderId)
      }
    }
  }
`;

export function getCancelUserOrderResult({ data }) {
  return data?.classMethods?.Order?.cancelOrder;
}

export const acceptTNCMutation = gql`
  mutation acceptTnc {
    classMethods {
      User {
        acceptTnc
      }
    }
  }
`;

export const createAccountMutation = gql`
  mutation createAccount($userId: ID, $ccard: createEwayCustomerCCard, $name: String) {
    classMethods {
      Account {
        createAccount(
          userId: $userId
          ccard: $ccard
          name: $name
          type: EWAY
          ewayCountry: "au"
        ) {
          id
          name
        }
      }
    }
  }
`;


export const updateAccountMutation = gql`
  mutation updateAccount(
    $accountId: ID!,
    $ccard: createEwayCustomerCCard
    # $ewayCountry: String,
    # $type: GQLAccountType,
    # $accountDefault: Boolean,
    # $customerId: Int,
  ) {
    classMethods {
      Account {
        updateAccount(
          accountId: $accountId,
          ccard: $ccard,
          # ewayCountry: $ewayCountry,
          # type: $type,
          # accountDefault: $accountDefault,
          # customerId: $customerId,
        ) {
          id
        }
      }
    }
  }
`;

export const updateOrderMutation = gql`
  mutation updateOrder($orderId: ID!, $productIds: [ID], $voucherCode: String) {
    classMethods {
      Order {
        updateOrder(
          orderId: $orderId,
          productIds: $productIds,
          voucherCode: $voucherCode,
        ) {
          id
          renewsAt
        }
      } 
    }
  }
`;

export function updateOrderResult({ data }) {
  return data?.classMethods?.Order?.updateOrder;
}

export const changePlanMutation = gql`
  mutation changePlan($orderId: ID!, $productIds: [ID], $voucherCode: String, $schedule: Boolean) {
    classMethods {
      Order {
        changePlan(
          orderId: $orderId,
          productIds: $productIds,
          voucherCode: $voucherCode,
          schedule: $schedule,
        ) {
          id
          renewsAt
        }
      } 
    }
  }
`;

export function changePlanResult({ data }) {
  return data?.classMethods?.Order?.changePlan;
}


export const purchaseOrderMutation = gql`
  mutation purchaseOrder($orderId: ID!) {
    classMethods {
      Order {
        purchaseOrder(
          orderId: $orderId,
        ) {
          id
        }
      } 
    }
  }
`;

export function purchaseOrderResult({ data }) {
  return data?.classMethods?.Order?.purchaseOrder;
}

export const resendVerificationMutation = gql`
  mutation resendVerification (
    $requestCode: String
  ) {
    classMethods {
      Verification {
        resendVerification (
          requestCode: $requestCode
        )
      }
    }
  }
`;

export const validateVerification = gql`
  query validateVerification (
    $requestCode: String
  ) {
    classMethods {
      Verification {
        validateVerification (
          requestCode: $requestCode
        )
      }
    }
  }
`;

export const createVerificationMutation = gql`
  mutation createVerification (
    $type: verificationType
    $userId: String
    $name: String
    $template: String
  ) {
    classMethods {
      Verification {
        createVerification (
          type: $type
          userId: $userId
          name: $name
          template: $template
        ) {
          id
          valid
        }
      }
    }
  }
`;

export const generateTotpMutation = gql`
  mutation generateTOTP($id: ID!) {
   classMethods {
    UserAuth {
      generateTOTP(id: $id) {
        otpAuthURL
        secret
        userAuthId
      }
    }
   }
  }
`;

export const removeTotp = gql`
  mutation removeTOTP($id: ID!) {
   classMethods {
    UserAuth {
      removeTOTP(id: $id)
    }
   }
  }
`;

export const getAdminGeneratedVerification = gql`
  query getAdminGeneratedVerification ($userId: String) {
    classMethods {
      Verification {
        adminGeneratedVerification(
          userId: $userId
        ) {
          id
          valid
          consumed
        }
      }
    }
  }
`;

export const denyVerificationMutation = gql`
  mutation denyVerification (
    $requestCode: String
  ) {
      classMethods {
        Verification {
          denyVerification(
            requestCode: $requestCode
          )
        }
      }
    }
`;
